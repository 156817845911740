import { DiscountType } from "../../../services/firebase/checkCoupon";
import { subscriptionPrice, subscriptionPriceString } from "../../../util/subscription/subscriptions";
export var RedeemState;
(function (RedeemState) {
    RedeemState[RedeemState["START"] = 0] = "START";
    RedeemState[RedeemState["SUBSCRIBE"] = 1] = "SUBSCRIBE";
    RedeemState[RedeemState["RESULT"] = 2] = "RESULT";
})(RedeemState || (RedeemState = {}));
export var assemblePriceString = function assemblePriceString(checkResponse) {
    var article = checkResponse.assignedArticles[0];
    return calculateNewPrice(checkResponse) + " EUR statt " + subscriptionPriceString(article.type, article.duration) + " EUR";
};
var calculateNewPrice = function calculateNewPrice(checkResponse) {
    var article = checkResponse.assignedArticles[0];
    var normalPrice = subscriptionPrice(article.type, article.duration);
    var newPrice;
    if (checkResponse.discountType === DiscountType.FIXED) {
        newPrice = normalPrice - checkResponse.discountAmount * 1.07;
    }
    else {
        newPrice = normalPrice - checkResponse.discountAmount * normalPrice;
    }
    return newPrice.toLocaleString("de", {
        minimumFractionDigits: 2
    });
};
